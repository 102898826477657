import React from 'react'

const ZeroLogo = () => (
  <svg
    className="logo"
    viewBox="0 0 438 125"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}>
    <path
      className="letters"
      d="m0 99.122 56.487-66.989H2.98V8.75h88.683v20.224L35.637 95.962h57.561v23.383H0V99.122zM160.545 121.456a65.345 65.345 0 0 1-24.956-4.529 55.35 55.35 0 0 1-18.752-12.33 52.562 52.562 0 0 1-11.692-17.972 57.941 57.941 0 0 1-4.003-21.375 63.732 63.732 0 0 1 4.003-22.653 54.86 54.86 0 0 1 11.589-18.65 54.191 54.191 0 0 1 18.638-12.638 64.769 64.769 0 0 1 25.173-4.631 63.957 63.957 0 0 1 25.071 4.631 55 55 0 0 1 18.535 12.535 53.254 53.254 0 0 1 11.512 18.215 60.997 60.997 0 0 1 3.838 21.746c0 1.957-.064 3.837-.205 5.781a40.432 40.432 0 0 1-.639 4.951h-81.699c.554 7.444 3.257 13.098 8.109 16.961a25.813 25.813 0 0 0 16.54 5.795 30.22 30.22 0 0 0 14.211-3.582 18.232 18.232 0 0 0 9.159-9.696l28.653 8.008a51.564 51.564 0 0 1-20.224 21.285c-9.133 5.432-20.087 8.148-32.861 8.148zm23.383-67.795c-.699-7.018-3.228-12.561-7.585-16.629a22.853 22.853 0 0 0-16.22-6.114 22.57 22.57 0 0 0-16.322 6.217c-4.264 4.144-6.758 9.657-7.483 16.539l47.61-.013zM305.05 37.608a64.747 64.747 0 0 0-22.117 4.004c-6.6 2.558-11.375 6.353-14.326 11.384v66.349h-33.705V8.75h30.968v22.334a46.954 46.954 0 0 1 14.646-17.486 33.753 33.753 0 0 1 19.059-6.523h3.377c.704-.001 1.407.067 2.098.204v30.329z"
    />
    <path
      d="M430.521 33.246C418.522 11.501 401.408.986 376.733.065c-7.905-.294-17.256.32-24.585 3.697h-.128c.742-.077 1.496-.141 2.226-.269a30.73 30.73 0 0 1 7.674-.358c1.749.144 3.482.435 5.181.87l.256.064.601.166c9.351 2.737 17.038 10.054 20.633 20.466a34.01 34.01 0 0 1 1.88 10.86l.192.115a30.449 30.449 0 0 1 14.129 25.689 30.452 30.452 0 0 1-15.831 26.692 33.36 33.36 0 0 0 11.871 4.516c15.785 2.839 30.162-5.117 35.253-19.635.243-.678.55-1.343.832-2.021.199-.99.348-1.989.447-2.993a59.158 59.158 0 0 0-6.843-34.678z"
      style={{ fill: 'url(#a)' }}
    />
    <path
      d="M332.372 106.873a53.334 53.334 0 0 0 5.321 5.948c.529.435 1.066.862 1.612 1.28a59.231 59.231 0 0 0 33.616 10.898c24.803.128 43.696-10.694 56.551-31.761a49.891 49.891 0 0 0 7.483-22.36c-.282.678-.589 1.343-.832 2.034-5.116 14.455-19.468 22.462-35.24 19.622A33.3 33.3 0 0 1 389 88.019l-1.075.563a30.411 30.411 0 0 1-13.475 3.149c-16.059 0-29.491-12.722-30.361-28.758v-1.458a31.732 31.732 0 0 0-9.248 7.675c-9.517 11.576-10.425 26.184-2.379 37.671"
      style={{ fill: 'url(#b)' }}
    />
    <path
      d="M388.591 24.714c-3.582-10.374-11.282-17.69-20.62-20.466l-.601-.166-.256-.064a32.817 32.817 0 0 0-5.181-.87 30.696 30.696 0 0 0-7.675.371c-.729.128-1.483.179-2.225.268h.128c-1.486.42-2.929.977-4.311 1.663-12.421 5.82-21.016 13.943-26.568 23.345-12.599 21.348-13.06 43.03-1.484 64.839a59.363 59.363 0 0 0 17.448 18.88l.46.32s-1.816-1.944-2.673-2.763c-.512-.511-2.558-3.108-2.648-3.185-7.509-11.673-6.465-26.996 2.558-37.543a22.118 22.118 0 0 1 9.082-6.395v-1.602c0-16.679 13.726-30.405 30.405-30.405a30.415 30.415 0 0 1 16.092 4.607 34.021 34.021 0 0 0-1.931-10.834z"
      style={{ fill: 'url(#c)' }}
    />
    <defs>
      <linearGradient
        id="a"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(111.39) rotate(63.585 1.757 2.664)">
        <stop offset="0" style={{ stopColor: '#ffdf00', stopOpacity: 1 }} />
        <stop offset=".45" style={{ stopColor: '#ffa700', stopOpacity: 1 }} />
        <stop offset=".49" style={{ stopColor: '#ffa100', stopOpacity: 1 }} />
        <stop offset=".53" style={{ stopColor: '#ff9000', stopOpacity: 1 }} />
        <stop offset=".58" style={{ stopColor: '#ff7500', stopOpacity: 1 }} />
        <stop offset=".64" style={{ stopColor: '#ff4e00', stopOpacity: 1 }} />
        <stop offset=".7" style={{ stopColor: '#ff1e00', stopOpacity: 1 }} />
        <stop offset=".73" style={{ stopColor: 'red', stopOpacity: 1 }} />
        <stop offset=".77" style={{ stopColor: '#ff001c', stopOpacity: 1 }} />
        <stop offset=".85" style={{ stopColor: '#ff014c', stopOpacity: 1 }} />
        <stop offset=".91" style={{ stopColor: '#ff026e', stopOpacity: 1 }} />
        <stop offset=".96" style={{ stopColor: '#ff0283', stopOpacity: 1 }} />
        <stop offset="1" style={{ stopColor: '#ff028b', stopOpacity: 1 }} />
      </linearGradient>
      <linearGradient
        id="b"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(107.113) rotate(19.324 -.452 9.286)">
        <stop offset="0" style={{ stopColor: '#005bc1', stopOpacity: 1 }} />
        <stop offset=".11" style={{ stopColor: '#3f40b8', stopOpacity: 1 }} />
        <stop offset=".3" style={{ stopColor: '#a912a9', stopOpacity: 1 }} />
        <stop offset=".39" style={{ stopColor: '#d300a3', stopOpacity: 1 }} />
        <stop offset="1" style={{ stopColor: '#be00a7', stopOpacity: 1 }} />
      </linearGradient>
      <linearGradient
        id="c"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(92.5696) rotate(-32.845 3.126 -5.29)">
        <stop offset="0" style={{ stopColor: '#049dff', stopOpacity: 1 }} />
        <stop offset=".09" style={{ stopColor: '#049dff', stopOpacity: 1 }} />
        <stop offset=".57" style={{ stopColor: '#01c2ff', stopOpacity: 1 }} />
        <stop offset=".63" style={{ stopColor: '#01baf7', stopOpacity: 1 }} />
        <stop offset=".72" style={{ stopColor: '#01a5e2', stopOpacity: 1 }} />
        <stop offset=".82" style={{ stopColor: '#0181bf', stopOpacity: 1 }} />
        <stop offset=".94" style={{ stopColor: '#005190', stopOpacity: 1 }} />
        <stop offset="1" style={{ stopColor: '#003877', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
  </svg>
)

export default ZeroLogo
