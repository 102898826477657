import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { TabNavigation } from '../../components/TabNavigation'
import { TopHeader } from '../../components/TopHeader'
import {
  rCallPHA,
  rClickChat,
  rClickLogin,
  rClickRegister,
} from '../../analytics/LandingAnalytics'
import {
  ActionButtonLabel,
  AuthTextContainer,
  AuthTextDesktopContainer,
  ButtonGridContainer,
  CallButtonContainerDesktop,
  ChatButtonContainerDesktop,
  ChatIconStyled,
  CircleButton,
  CircleButtonContainer,
  CircleButtonDesktop,
  ContentContainer,
  DesktopContentContainer,
  DesktopLandingContainer,
  FlexWrapper,
  GreetingTextItem,
  HelpText,
  HelpTextDesktop,
  LandingContainer,
  PhoneIconStyled,
  WelcomeText,
  WelcomeTextContainerDesktop,
} from './styledComponents'
import zenDesk from '../../utils/zendeskWidget'
import { isUserOnMobile } from '../../utils'

// Type Definitions
interface Props {
  step: number
  style?: any
  displayAuthText?: boolean
  firstName?: string
  userFinishedLoading?: boolean
  isLoggedIn: boolean
}

export const Landing = (props: Props) => {
  // If the user is not finished loadng do not display component
  if (!props.userFinishedLoading && props.isLoggedIn) {
    return null
  }

  const authText = props.displayAuthText ? (
    <AuthTextContainer container>
      <Grid item xs={1} sm={1} md={1} lg={1} />
      <GreetingTextItem item xs={10} sm={10} md={10} lg={10}>
        <h5
          onClick={() => {
            rClickRegister()
          }}>
          New here?{' '}
          <Link to="/how-it-works" data-cy="landing-page-register">
            Register
          </Link>
        </h5>
        <h5
          onClick={() => {
            rClickLogin()
          }}>
          Already a member? <Link to="/login">Log In</Link>
        </h5>
      </GreetingTextItem>
      <Grid item xs={1} sm={1} md={1} lg={1} />
    </AuthTextContainer>
  ) : null

  // Customize the welcome message content if we have a first name for the user
  // Jim wanted us to remove the text that shows up when not logged in, but might change his mind so just made it an empty string
  const welcomeMessageContent = props.firstName ? (
    <WelcomeTextContainerDesktop>
      <WelcomeText>Hello {props.firstName},</WelcomeText>
    </WelcomeTextContainerDesktop>
  ) : null

  const onClickChat = () => {
    rClickChat()
    zenDesk().then((sdk) => {
      sdk.toggle()
    })
  }

  const onClickCall = () => {
    rCallPHA()
  }

  // Initialize the ZenDesk SDK if the privacy policy is not out (the button blocks the accept button on mobile)
  zenDesk().then((sdk) => {
    sdk.showWidget()
    sdk.onUnreadMessages((v: number) => {
      if (v > 0) {
        sdk.toggle()
      }
    })
  })

  // Render the desktop layout
  const renderDesktopLayout = () => {
    return (
      <FlexWrapper>
        <DesktopLandingContainer>
          <TopHeader desktop />
          <DesktopContentContainer>
            <Grid container>
              <Grid item xs={1} sm={1} md={1} lg={1} />
              <GreetingTextItem item xs={10} sm={10} md={10} lg={10}>
                {welcomeMessageContent}
                <HelpTextDesktop>How can we help you today?</HelpTextDesktop>
              </GreetingTextItem>
              <Grid item xs={1} sm={1} md={1} lg={1} />
            </Grid>
            <AuthTextDesktopContainer>{authText}</AuthTextDesktopContainer>
            <Grid container>
              <Grid item xs={1} sm={1} md={1} />
              <ButtonGridContainer item xs={10} sm={10} md={10}>
                <ChatButtonContainerDesktop>
                  <div>
                    {/* @ts-ignore */}
                    <CircleButtonDesktop
                      variant="contained"
                      disableElevation
                      onClick={onClickChat}>
                      <ChatIconStyled />
                    </CircleButtonDesktop>
                    <ActionButtonLabel>Chat</ActionButtonLabel>
                  </div>
                </ChatButtonContainerDesktop>
                <CallButtonContainerDesktop>
                  <div onClick={onClickCall}>
                    <a href="tel:18558160001">
                      <CircleButtonDesktop color="primary" disableElevation>
                        <PhoneIconStyled />
                      </CircleButtonDesktop>
                      <ActionButtonLabel>Call</ActionButtonLabel>
                    </a>
                  </div>
                </CallButtonContainerDesktop>
              </ButtonGridContainer>
              <Grid item xs={1} sm={1} md={1} />
            </Grid>
          </DesktopContentContainer>
        </DesktopLandingContainer>
      </FlexWrapper>
    )
  }

  // If the user is on desktop then render the desktop layout
  if (!isUserOnMobile()) {
    return renderDesktopLayout()
  }

  // Not on desktop, render the mobile layout
  return (
    <LandingContainer>
      <TopHeader showSettingsIcon />
      <ContentContainer
        container
        spacing={1}
        direction="column"
        justify="space-between"
        alignItems="center">
        <Grid container>
          <Grid item xs={1} sm={1} md={1} lg={1} />
          <GreetingTextItem item xs={10} sm={10} md={10} lg={10}>
            <WelcomeText>{welcomeMessageContent}</WelcomeText>
            <HelpText>How can we help you today?</HelpText>
          </GreetingTextItem>
          <Grid item xs={1} sm={1} md={1} lg={1} />
        </Grid>
        {authText}
        <Grid container>
          <Grid item xs={1} sm={1} md={1} />
          <Grid item xs={5} sm={5} md={5}>
            <CircleButtonContainer>
              {/* @ts-ignore */}
              <CircleButton
                variant="contained"
                disableElevation
                onClick={onClickChat}>
                <ChatIconStyled />
              </CircleButton>
              <ActionButtonLabel>Chat</ActionButtonLabel>
            </CircleButtonContainer>
          </Grid>
          <Grid item xs={5} sm={5} md={5}>
            <a href="tel:18558160001">
              <CircleButtonContainer>
                <CircleButton color="primary" disableElevation>
                  <PhoneIconStyled />
                </CircleButton>
                <ActionButtonLabel>Call</ActionButtonLabel>
              </CircleButtonContainer>
            </a>
          </Grid>
          <Grid item xs={1} sm={1} md={1} />
        </Grid>
      </ContentContainer>
      <TabNavigation />
    </LandingContainer>
  )
}
