import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 3px;
`

const Rect = ({ color }: any) => {
  return (
    <div>
      <svg width="100%">
        <rect width="100%" height="3px" fill={color} />
      </svg>
    </div>
  )
}

const ColorBar = () => {
  return (
    <Container>
      <Rect color="#0048c9" />
      <Rect color="#00c1ff" />
      <Rect color="#7400b7" />
      <Rect color="#f400a2" />
      <Rect color="#ff0000" />
      <Rect color="#ff9400" />
    </Container>
  )
}

export default ColorBar
