import React from 'react'

const Scissors = () => (
  <svg
    className="scissors"
    viewBox="0 0 94 140"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2,
    }}>
    <path
      d="m44.391 78.23-.825 9.669 4.245 2.829c.668-.118 1.218-.177 1.65-.177 1.651 0 4.245 1.514 7.782 4.54l10.258 8.666c5.463 4.638 10.356 6.956 14.679 6.956 3.105 0 5.738-1.1 7.9-3.301 2.161-2.201 3.242-4.893 3.242-8.077 0-4.126-1.827-7.86-5.482-11.201-3.655-3.34-7.743-5.011-12.263-5.011-2.672 0-5.698 1.003-9.078 3.007l-1.179.707c-1.808 1.062-3.302 1.592-4.481 1.592-1.611 0-3.851-1.159-6.72-3.478a29.875 29.875 0 0 0-2.123-1.592l-7.605-5.129zm-4.598 15.8 8.136-78.113C46.081 10.218 42.387 4.913 36.845 0l-5.954 81.532c-.157 1.847-.236 3.085-.236 3.714 0 .55.02 1.002.059 1.356l.236 3.006c0 2.909-1.474 4.952-4.421 6.131l-1.415.59c-9.236 3.655-13.854 11.653-13.854 23.994 0 5.659 1.15 10.297 3.449 13.912 2.299 3.616 5.217 5.424 8.754 5.424 2.987 0 5.149-1.051 6.485-3.154 1.336-2.102 2.299-5.984 2.889-11.643l2.358-21.93c.354-3.42.805-5.65 1.356-6.692.55-1.041 1.631-1.778 3.242-2.21zM28.062 78.407l.884-10.611L0 48.223v13.029l28.062 17.155zm45.393 24.289-2.476-2.005c-3.262-2.594-4.893-4.912-4.893-6.956 0-1.494.914-2.869 2.741-4.127 1.828-1.257 3.842-1.886 6.043-1.886 3.616 0 6.878 1.159 9.786 3.478 2.909 2.319 4.363 4.893 4.363 7.723 0 2.043-.688 3.792-2.064 5.247-1.375 1.454-3.026 2.181-4.952 2.181-2.436 0-5.286-1.219-8.548-3.655zm-43.389 7.84-1.71 15.859c-.629 5.934-2.279 8.902-4.952 8.902-2.161 0-3.999-1.435-5.512-4.304-1.513-2.869-2.27-6.347-2.27-10.435 0-5.187 1.081-9.737 3.243-13.647 2.161-3.911 4.677-5.866 7.546-5.866 2.515 0 3.773 1.965 3.773 5.895 0 1.337-.039 2.535-.118 3.596zm3.301-31.834c0-1.769.983-2.653 2.948-2.653 1.808 0 2.712 1.042 2.712 3.125 0 1.768-.963 2.652-2.889 2.652-1.847 0-2.771-1.041-2.771-3.124z"
      style={{ fillRule: 'nonzero' }}
    />
  </svg>
)

export default Scissors
